export const serviceSlides = [
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/desktop/top-soil.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/desktop/gravel.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/desktop/land-grading.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/desktop/excavation.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/desktop/drainage.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/desktop/patios.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/desktop/lawn-care.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/desktop/site-prep.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/desktop/driveways.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/desktop/landscape.png"
];

export const serviceSlidesMobile = [
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/mobile/top-soil-mobile.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/mobile/gravel-mobile.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/mobile/land-grading-mobile.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/mobile/excavation-mobile.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/mobile/drainage-mobile.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/mobile/patios-mobile.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/mobile/lawn-care-mobile.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/mobile/site-prep-mobile.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/mobile/driveways-mobile.png",
    "https://wz-excavating-bucket.s3.amazonaws.com/banners/mobile/landscape-mobile.png"
];

export const galleryThumbnails = [
    { src: "https://wz-excavating-bucket.s3.amazonaws.com/services/erosion-control.jpg", width: 3840, height: 2160 },
    { src: "https://wz-excavating-bucket.s3.amazonaws.com/services/gravel-decorative-landscape.jpg", width: 3840, height: 2160 },
    { src: "https://wz-excavating-bucket.s3.amazonaws.com/services/land-grading-site-prep.jpeg.webp", width: 3840, height: 2160 },
    { src: "https://wz-excavating-bucket.s3.amazonaws.com/services/foundation-excavation.jpg", width: 3840, height: 2160 },
    { src: "https://wz-excavating-bucket.s3.amazonaws.com/services/trenching-scaled.webp", width: 3840, height: 2160},
    { src: "https://wz-excavating-bucket.s3.amazonaws.com/services/patio-installation.jpg", width: 3840, height: 2160 },
    { src: "https://wz-excavating-bucket.s3.amazonaws.com/services/seasonal-services.jpg", width: 3840, height: 2160},
    { src: "https://wz-excavating-bucket.s3.amazonaws.com/services/stormwater-management.jpg", width: 3840, height: 2160 },
    { src: "https://wz-excavating-bucket.s3.amazonaws.com/services/driveway-design.jpg", width: 3840, height: 2160 },
    { src: "https://wz-excavating-bucket.s3.amazonaws.com/services/ecological-landscaping.webp", width: 3840, height: 2160 }
];

export const testimonials = [
    { 
        id: 1, 
        name: "Sarah T.", 
        location: "Snohomish, WA", 
        review: "WZ Trucking & Excavation completely transformed our backyard with a beautiful patio and walkways. The team was incredibly professional, efficient, and detail-oriented. They worked closely with us to understand our vision and delivered results that exceeded our expectations. Our outdoor space is now the envy of the neighborhood! We highly recommend WZ for any landscaping project."
    },
    { 
        id: 2, 
        name: "Mark R.", 
        location: "Everett, WA", 
        review: "We hired WZ Trucking & Excavation to handle the site preparation for our new commercial building, and they did an outstanding job. From grading to drainage systems, their expertise was evident in every phase of the project. They completed the work on time and within budget, and the quality was top-notch. Their attention to detail and commitment to excellence make them a trusted partner for any construction needs."
    },
    { 
        id: 3, 
        name: "Lisa M.", 
        location: "Lake Stevens, WA", 
        review: "WZ Trucking & Excavation did an amazing job on our driveway and retaining walls. The team was friendly, professional, and clearly passionate about their work. They provided great suggestions that improved the functionality and look of our property. The project was completed efficiently, and the results are stunning. We couldn’t be happier with their services and would definitely work with them again in the future."
    },
    {
        id: 4,
        name: "John H.",
        location: "Monroe, WA",
        review: "We hired WZ Trucking & Excavation for a major landscaping overhaul, including a new lawn, topsoil, and a gravel driveway. The team was incredible from start to finish! They were punctual, professional, and went above and beyond to ensure every detail was perfect. The quality of their work was exceptional, and our property has never looked better. We’re thrilled with the results and highly recommend WZ for any landscaping or excavation project."
    }
]